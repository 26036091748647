import {BaseEntity} from './base-entity';

export class Representative extends BaseEntity {

    private readonly name: string;
    private readonly contactNumber: string;
    private readonly email: string;

    public static fromDataStoreRecord(id: string, representative: any): Representative {
        return Object.assign(new Representative(
            id,
            representative.name,
            representative.contactNumber,
            representative.email), representative);
    }

    constructor(id: string, name: string, contactNumber: string, email: string) {
        super(id);
        this.name = name;
        this.contactNumber = contactNumber;
        this.email = email;
    }

    public get Name(): string {
        return this.name;
    }

    public get ContactNumber(): string {
        return this.contactNumber;
    }

    public get Email(): string {
        return this.email;
    }

    public toDataStoreRecord(): any {
        return Object.assign({}, this);
    }
}
