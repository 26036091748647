import { Injectable } from '@angular/core';
import { EstablishmentService } from './establishment.service';
import { ResourceService } from './resource.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EstablishmentStatusService {
    private resultSubject = new Subject<boolean>();
    private isChecking = false;

    constructor(private establishmentService: EstablishmentService,
        private resourceService: ResourceService) {
            this.establishmentService.waitOnActiveEstablishmentLoaded().subscribe(result => {
                if (result !== null) {
                    this.checkCanLoadAppointments();
                }
            })
    }

    public waitOnLoadAppointmentCheck(): Observable<boolean> {
        if (!this.isChecking) {
            this.checkCanLoadAppointments();
        }
        return this.resultSubject;
    }

    private checkCanLoadAppointments(): void {
        if (!this.isLinkedToEstablishment()) {
            return;
        }

        const activeEstablishment = this.establishmentService.activeEstablishment;
        this.resourceService.getResources(activeEstablishment.id).then(resources => {
            this.establishmentService.getServiceOptions(activeEstablishment.id).then(services => {
                const canLoad = (resources.length > 0)
                this.resultSubject.next(canLoad);
            }).catch(err => {
                this.resultSubject.next(false);
            });
        }).catch(err => {
            this.resultSubject.next(false);
        });
    }

    public isLinkedToEstablishment(): boolean {
        return this.establishmentService.hasActiveEstablishment;
    }
}
