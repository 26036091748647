export class TimeSlot {
  id: number;
  description: string;

  constructor(id: number, description: string) {
    this.id = id;
    this.description = description;
  }
}

export class Day {
  id: number;
  description: string;

  constructor(id: number, description: string) {
    this.id = id;
    this.description = description;
  }
}

export class Month {
  id: number;
  description: string;

  constructor(id: number, description: string) {
    this.id = id;
    this.description = description;
  }
}

export class Year {
  id: number;
  description: string;

  constructor(id: number, description: string) {
    this.id = id;
    this.description = description;
  }
}

export class SelectionItemOption {
  id: number;
  description: string;
  type: string;
  preferenceId: number;
  selected: boolean;
  constructor(id: number, description: string, preferenceId: number) {
    this.id = id;
    this.description = description;
    this.type = "multiple"
    this.preferenceId = preferenceId;
  }
}

export class SelectionItem {
  id: number;
  description: string;
  options: SelectionItemOption[] = [];

  constructor(id: number, description: string) {
    this.id = id;
    this.description = description;
  }

  addOptionOnly(option: SelectionItemOption) {
    this.options.push(option);
  }

  addOption(description: string) {
    const id = this.options.length + 1;
    this.addOptionOnly(new SelectionItemOption(id, description, this.id));
  }

  addYesNoOption(description: string) {
    const id = this.options.length + 1;
    const item = new SelectionItemOption(id, description, this.id)
    item.type = "true-false";
    this.addOptionOnly(item);
  }
}

export class SelectionItemPreference {
  preferenceId: number;
  optionId: number;
  constructor(preferenceId: number, optionId: number) {
    this.preferenceId = preferenceId;
    this.optionId = optionId;
  }
}

export class ProductCategory {
  id: string;
  name: string;
  subCategories: ProductCategory[];
  subCategoryIds: string[];
  productIds: string[];
  products: Product[];
  parentCategoryId: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
    this.subCategories = [];
    this.products = [];
  }

  addSubCategory(category: ProductCategory) {
    this.subCategories.push(category);
  }

  addProduct(product: Product) {
    const existingProduct = this.products.find(r => r.id === product.id);
    if (existingProduct === null || existingProduct === undefined) {
      this.products.push(product);
    }
  }
}

export class Product {
  id: string;
  name: string;
  description: string;
  price: number;
  volume: ProductVolume;
  isOnPromotion: boolean;
  categoryId: string;
  imgUrl: string;
  noImageText: string;

  public static fromDataStoreRecord(id: string, productData: any): Product {
    const product = Object.assign(new Product(id, productData.name, productData.description, productData.price), productData);
    product.id = id;
    return product;
  }

  constructor(id: string, name: string, description: string, price: number) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.price = price;
  }

}


export class ProductVolume {
  unit: string;
  volume: number;

  constructor(volume: number, unit: string) {
    this.unit = unit;
    this.volume = volume;
  }

  get name(): string {
    return this.volume + " " + this.unit;
  }
}

export class ShoppingCart {
  items: ShoppingCartItem[];

  constructor() {
    this.items = [];
  }

  addItem(item: ShoppingCartItem) {
    this.items.push(item);
  }

  removeItem(id: string) {
    for (let i = this.items.length - 1; i >= 0; i--) {
      if (this.items[i].id === id) {
        this.items.splice(i, 1);
      }
    }
  }

  adjustItemQuantity(id: string, newQuantity: number) {
    const item = this.items.find(r => r.id === id);
    if (item !== null && item !== undefined) {
      item.quantity = newQuantity;
    }
  }

  get totalAmount(): number {
    return this.items.reduce((sum, current) => sum + current.price, 0);
  }

  get numberOfItems(): number {
    return this.items.length;
  }

  clear() {
    this.items.length = 0;
  }
}

export class ShoppingCartItem {
  id: string;
  productId: string;
  productName: string;
  productDescription: string;
  productHouseId: string;
  productHouseName: string;
  quantity: number;
  price: number;
  imgUrl: string;
  noImageText: string;
  isOnPromotion: boolean;

  constructor(id: string, productHouseId: string, productHouseName: string, productId: string, productName: string,
    productDescription: string, price: number, quantity: number, imgUrl: string, noImageText: string, isOnPromotion: boolean) {
    this.id = id;
    this.productHouseId = productHouseId;
    this.productHouseName = productHouseName;
    this.productId = productId;
    this.productName = productName;
    this.price = price;
    this.quantity = quantity;
    this.productDescription = productDescription;
    this.imgUrl = imgUrl;
    this.noImageText = noImageText;
    this.isOnPromotion = isOnPromotion;
  }

  get totalAmount(): number {
    return this.quantity * this.price;
  }

}
