import {Injectable, NgZone} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Router} from "@angular/router";
import {User} from 'app/model/user';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  constructor(private afs: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    public ngZone: NgZone) {
    /* Saving user data in local storage when logged in and setting up null when logged out */
    this.angularFireAuth.authState.subscribe(user => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', null);
      }
    })
  }

  /* Sign up */
  SignUp(email: string, password: string, displayName: string): Promise<any> {
    return new Promise((resolve) => {
      return this.angularFireAuth
        .createUserWithEmailAndPassword(email, password)
        .then(res => {
          this.UpdateDisplayName(res.user, displayName);
          this.SendEmailVerification(res.user);
          resolve({userId: res.user.uid});
          console.log('Successfully signed up!', res, res.user, res.user.uid);
        })
        .catch(error => {
          console.log('Something is wrong:', error.message);
          resolve({message: error.message})
        });
    });
  }

  /* Sign in */
  SignIn(email: string, password: string) {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {

        if (!result.user.emailVerified) { return {emailVerified: false}; }

        this.SetUserData(result.user);
        localStorage.setItem('user', JSON.stringify(result.user));

        return true;
      }).catch((error) => {
        console.log(error);
        return false;
      })
  }

  /* Sign out */
  SignOut() {
    return this.angularFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    })
  }

  SignOutSilent() {
    return this.angularFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
    })
  }

  UpdateDisplayName(currentUser, displayName: string): void {
    if (!currentUser) {
      console.error("Unable to update display name. Please provide currentUser!");
    } else if (!displayName || displayName === "") {
      console.error("Please provide display name!");
    } else if (currentUser && !currentUser.updateProfile) {
      console.error("Unable to update display name. Please provide the correct currentUser object!");
    } else {
      currentUser.updateProfile({ displayName: displayName });
      this.angularFireAuth.updateCurrentUser(currentUser).then(function () {
        console.log('Successfully updated display name');
      }).catch(function (error) {
        console.log('Error trying to update display name:', error.message);
      });
    }
  }

  SendEmailVerification(currentUser): void {
    if (!currentUser) {
      console.error("Unable to send Email Verification. Please provide currentUser!");
    } else if (currentUser && !currentUser.sendEmailVerification) {
      console.error("Unable to send Email Verification. The currentUser you have provided does not have the function 'sendEmailVerification'. Please provide the correct currentUser");
    } else {
      currentUser.sendEmailVerification().then(function () {
        console.log('Email verification was send');
      }).catch(function (error) {
        console.log('Error trying to send email verification:', error.message);
      });
    }
  }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  get userData(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    return userRef.set({ lastLoginDate: new Date() }, {
      merge: true
    })
  }
}
