import { Injectable } from '@angular/core';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() { }

  showSuccess(message: string) {
    var type = "success";
    var icon = "check_circle";
    var from = 'top';
    var align = 'center';

    this.showNotification(from, align, type, icon, message, 500);
  }

  showError(message: string) {
    var type = "danger";
    var icon = "cancel";
    var from = 'top';
    var align = 'center';

    this.showNotification(from, align, type, icon, message, 500);
  }

  showFeature(message: string) {
    var type = "info";
    var icon = "notifications";
    var from = 'top';
    var align = 'center';

    this.showNotification(from, align, type, icon, message, 5000);
  }

  showNotification(from, align, type, icon, message, delay) {
    $.notify({
      icon: icon,
      message: message

    }, {
      type: type,
      delay: delay,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">' + icon + '</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
}
