<div class="logo">
    <a href="" class="simple-text text-center w-100">
        {{getTitle()}}
    </a>
</div>
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item">
                <a *ngIf="isLinkedToEstablishment()" class="nav-link collapsed" data-toggle="collapse" 
                [attr.href]="!canLoadAppointments ? null : '#pagesExamples'" aria-expanded="false" 
                [class.disabled]="!canLoadAppointments">
                    <i class="material-icons">date_range</i>
                    <p> Appointments
                      <b class="caret"></b>
                    </p>
                  </a>
                  <div class="collapse" id="pagesExamples">
                    <ul class="nav">
                      <li  *ngFor="let arrayItem of resources; let i=index" class="nav-item ">
                        <a class="nav-link" href="javascript:void(0)" (click)="onResourceClicked(arrayItem)">
                          <span class="sidebar-normal"> {{arrayItem.name}} </span>
                        </a>
                      </li>
                    </ul>
                  </div>
            </li>
            <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
                <a *ngIf="!menuItem.auth || (menuItem.auth && isLinkedToEstablishment)" class="nav-link"
                    [routerLink]="[menuItem.path]">
                    <i class="material-icons">{{menuItem.icon}}</i>
                    <p>{{menuItem.title}}</p>
                </a>
            </li>
            <li *ngIf="isLinkedToSupplier()" class="nav-item">
                <a class="nav-link" [routerLink]="" (click)="goToManageOrders()">
                    <i class="material-icons">inbox</i>
                    <p>Order Management</p>
                </a>
            </li>
            <li *ngIf="authService.isLoggedIn" class="nav-item">
                <a class="nav-link" [routerLink]="" (click)="goToFeedback()">
                    <i class="material-icons">record_voice_over</i>
                    <p>Feedback</p>
                </a>
            </li>
            <li *ngIf="authService.isLoggedIn" class="nav-item">
                <a class="nav-link" [routerLink]="" (click)="LogOut()">
                    <i class="material-icons">lock</i>
                    <p>Log Out</p>
                </a>
            </li>
        </ul>
    </div>
    <div class="sidemenu-terms-conditions d-block d-sm-none">
        <a href="http://cutncape.com/terms-and-conditions.html" target="_blank">Terms & Conditions</a>
    </div>
    <div *ngIf="!isMobileMenu()">
        <ul class="nav">
            <li class="nav-item">
                <a *ngIf="isLinkedToEstablishment()" class="nav-link collapsed" data-toggle="collapse" 
                [attr.href]="!canLoadAppointments ? null : '#pagesExamples'" aria-expanded="false" 
                [class.disabled]="!canLoadAppointments">
                    <i class="material-icons">date_range</i>
                    <p> Appointments
                      <b class="caret"></b>
                    </p>
                  </a>
                  <div class="collapse" id="pagesExamples">
                    <ul class="nav">
                      <li  *ngFor="let arrayItem of resources; let i=index" class="nav-item ">
                        <a class="nav-link" href="javascript:void(0)" (click)="onResourceClicked(arrayItem)">
                          <span class="sidebar-normal"> {{arrayItem.name}} </span>
                        </a>
                      </li>
                    </ul>
                  </div>
            </li>
            <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
                <a *ngIf="(!menuItem.auth || (menuItem.auth && isLinkedToEstablishment())) && canAccessStore(menuItem.title)" class="nav-link"
                    [routerLink]="[menuItem.path]">
                    <i class="material-icons">{{menuItem.icon}}</i>
                    <p>{{menuItem.title}} </p>
                </a>
            </li>
            <li *ngIf="isLinkedToSupplier()" class="nav-item">
                <a class="nav-link" [routerLink]="" (click)="goToManageOrders()">
                    <i class="material-icons">inbox</i>
                    <p>Order Management</p>
                </a>
            </li>

            <li *ngIf="authService.isLoggedIn" class="nav-item">
                <a class="nav-link" [routerLink]="" (click)="goToFeedback()">
                    <i class="material-icons">record_voice_over</i>
                    <p>Feedback</p>
                </a>
            </li>

            <li *ngIf="authService.isLoggedIn" class="nav-item">
                <a class="nav-link" [routerLink]="" (click)="LogOut()">
                    <i class="material-icons">lock</i>
                    <p>Log Out</p>
                </a>
            </li>
        </ul>
    </div>
</div>
