<div class="wrapper">
    <div *ngIf="isLoginValid()" class="sidebar" data-color="azure" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-4.jpg)"></div>
    </div>
    <div class="main-panel">
        <app-navbar *ngIf="isLoginValid()"></app-navbar>
        <router-outlet></router-outlet>
        <footer class="footer d-none d-sm-block ">
            <div class="container-fluid">
                <!-- Contact Us link -->
                <div class="d-flex flex-row justify-content-center align-items-center">
                    <p class="mb-0">Contact us at <a href="mailto:support@cutncape.com" class="contact-us-link">support@cutncape.com</a>&nbsp;|&nbsp;</p> 
                    <!-- Terms & Conditions link -->
                    <p class="mb-0"><a href="http://cutncape.com/terms-and-conditions.html" target="_blank">Terms & Conditions</a></p>
                </div>
            </div>
        </footer>
    </div>
</div>
