import { HealthScreening } from './health-screening';
import { BaseEntity } from "./base-entity";

export class Resource extends BaseEntity {
    name: string;
    telephoneNumber: string;
    emailAddress: string;
    establishmentId: string;
    healthScreenings: HealthScreening[] = new Array();

    public static fromDataStoreRecord(id: string, resourceData: any): Resource {
        const resource = Object.assign(new Resource(id, resourceData.name, resourceData.telephoneNumber, resourceData.emailAddress),
        resourceData);
        if (resourceData.healthScreenings) {
            resource.healthScreenings = resourceData.healthScreenings.map((obj) => {
                return HealthScreening.fromDataStoreRecord(obj.id, obj);
            });
        }
        resource.id = id;
        return resource;
    }

    constructor(id: string, name: string, telephoneNumber: string, emailAddress: string) {
        super(id);
        this.name = name;
        this.telephoneNumber = telephoneNumber;
        this.emailAddress = emailAddress;
    }

    addHealthScreening(screening: HealthScreening) {
        this.healthScreenings.push(screening);
    }

    toDataStoreRecord(establishmentId: string) {
        this.establishmentId = establishmentId;
        const dataStoreResource = Object.assign({}, this);
        dataStoreResource.healthScreenings = this.healthScreenings.map((obj) => { return obj.toDataStoreRecord() });

        return dataStoreResource;
    }

}
