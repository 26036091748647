import {BaseEntity} from './base-entity';

export class HealthScreening extends BaseEntity {
    screeningAnswers: ScreeningAnswer[] = [];
    completedBy: string;
    screeningDate: Date;
    person: Person;
    temperatureBefore: number;
    temperatureAfter: number;
    parentId: string;

    public static fromDataStoreRecord(id: string, data: any): HealthScreening {
        const record = Object.assign(new HealthScreening(id), data);
        record.id = id;
        return record;
    }

    constructor(id: string) {
        super(id);
    }

    toDataStoreRecord() {
        const screening = Object.assign({}, this);
        screening.screeningAnswers = this.screeningAnswers.map((obj) => { return Object.assign({}, obj) });
        return screening;
    }


}

export class Person extends BaseEntity {
    // referenceId id is a reference to client id on the health screening
    referenceId: string;
    idNumber: string;
    telephone: string;
    name: string;
    age: number;

    public static fromDataStoreRecord(id: string, data: any): Person {
        const person = Object.assign(new Person(id), data);
        person.id = id;
        return person;
    }

    constructor(id: string) {
        super(id);
    }

    toDataStoreRecord(id: string) {
        return Object.assign({}, this);
    }
}

export class ScreeningAnswer extends BaseEntity {

    screeningQuestion: ScreeningQuestion;
    yes: boolean = false;
    no: boolean = false;

    public static fromDataStoreRecord(id: string, data: any): Person {
        const person = Object.assign(new ScreeningAnswer(id), data);
        person.id = id;
        return person;
    }

    public static getAllQuestion(): ScreeningAnswer[] {
        const screeningAnswers: ScreeningAnswer[] = []
        for (let i = 0; i < (Object.keys(ScreeningQuestion).length / 2); i ++) {
            screeningAnswers.push(new ScreeningAnswer(null, i))
        }
        console.log(screeningAnswers);
        return screeningAnswers;
    }

    constructor(id: string, screeningQuestion: ScreeningQuestion = undefined) {
        super(id);
        this.screeningQuestion = screeningQuestion;
    }

    toDataStoreRecord(id: string) {
        return Object.assign({}, this);
    }
}

export enum ScreeningQuestion {
    'Have you been out of the country since December 2019?',
    'Have you been in contact with a positive COVID-19 patient?',
    // Serious + Common
    'Do you have a history of fever?',
    'Do you have a dry cough?',
    'Are you suffering from fatigue/tiredness?',
    'Do you have difficulty breathing/shortness of breath?',
    'Are you suffering from any chest pain or pressure on your chest?',
    'Are you experiencing loss of speech or movement?',

    // General
    'Do you have a headache?',
    'Are you experiencing any chills?"',
    'Do you have a sore throat?',
    'Are you experiencing any nausea/vomiting?',
    'Are you experiencing diarrhoea?',
    'Do you suffer from myalgia/body pains?',
    'Have you recently loss your sense of smell?',
    'Are you suffering from general weakness?',
    'Are you experience any irritability/confusion?',
    'Have you been feeling tired lately?'
}
