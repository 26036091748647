import { Injectable } from '@angular/core';
import { Resource } from 'app/model/resource';
import { EstablishmentService } from './establishment.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  activeResourceList: Resource[] = new Array<Resource>();
  loadedActiveResourceList = false;
  constructor(private fireStore: AngularFirestore, private establishmentService: EstablishmentService) {
  }

  upSertResource(resource: Resource): Promise<Resource> {
    if (!resource.isTransient()) {
      return this.updateResource(resource);
    } else {
      return this.insertResource(resource);
    }
  }

  getResources(establishmentId: string): Promise<Resource[]> {
    if (this.loadedActiveResourceList && this.activeResourceList) {
      return new Promise((resolve, reject) => {
        resolve(this.activeResourceList.sort((a, b) => (a.name > b.name) ? 1 : -1))
      });
    }

    return new Promise((resolve, reject) => {
      return this.fireStore.collection("resources", r => r.where("establishmentId", "==", establishmentId)).get()
        .subscribe(data => {
          if (data === null || data === undefined || data.docs.length === 0) {
            resolve(this.activeResourceList);
          } else {
            this.activeResourceList.length = 0;
            const resourcesData = data.docs;
            resourcesData.forEach(element => {
              const clientData = element.data();
              const id = element.id;
              const resource = Resource.fromDataStoreRecord(id, clientData);
              this.activeResourceList.push(resource)
            });
            this.loadedActiveResourceList = true;
            resolve(this.activeResourceList.sort((a, b) => (a.name > b.name) ? 1 : -1));
          }
        });
    });
  }

  getResourceById(id: string): Promise<Resource> {
    const resources = this.activeResourceList;
    const obj = resources.find(o => o.id === id);
    if (obj) {
      return new Promise((resolve, reject) => { resolve(obj) });
    }

    return this.remoteGetResourceById(id)
  }

  private remoteGetResourceById(id: string): Promise<Resource> {
    return new Promise((resolve, reject) => {
      this.fireStore.collection('resources').doc(id).get()
        .subscribe(data => {
          if (data === null || data === undefined || !data.exists) {
            resolve(null);
          } else {
            const clientData = data.data();
            const id = data.id;
            const client = Resource.fromDataStoreRecord(id, clientData);
            resolve(client);
          }
        });
    });
  }

  deleteFromLocalList(id: string) {
    for (let i = this.activeResourceList.length - 1; i >= 0; i--) {
      if (this.activeResourceList[i].id === id) {
        this.activeResourceList.splice(i, 1);
      }
    }
  }

  updateResource(updatedResource: Resource): Promise<Resource> {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection('resources').doc(updatedResource.id).set(
        Object.assign({}, updatedResource.toDataStoreRecord(this.establishmentService.activeEstablishment.id))
        , { merge: true }).then(function (doc) {
          this.deleteFromLocalList(updatedResource.id);
          this.activeResourceList.push(updatedResource);
          resolve(updatedResource);
        }.bind(this)).catch(ex => {
          console.log(ex);
        });
    });
  }

  insertResource(newResource: Resource): Promise<Resource> {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("resources").add(
          Object.assign({}, newResource.toDataStoreRecord(this.establishmentService.activeEstablishment.id))).then(function (doc) {
        newResource.id = doc.id;
        this.activeResourceList.push(newResource);
        resolve(newResource);
      }.bind(this)).catch(ex => {
        console.log(ex);
      });
    });
  }

  clearActiveResourceList() {
    this.loadedActiveResourceList = false;
    this.activeResourceList = new Array<Resource>();
  }
}
