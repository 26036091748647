import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Services
import { LocationService } from './../services/location.service';
import { AuthenticationService } from '../services/authentication.service'
import { UserService } from '../services/user-service'
import { EstablishmentService } from '../services/establishment.service'
import { ResourceService } from '../services/resource.service'

// Models / Entities
import { Province } from 'app/model/province';
import { Establishment } from '../model/establishment'
import { Resource } from '../model/resource'

export enum StepDescription {
  step1 = 'User Details',
  step2 = 'Business Details',
  step3 = 'Business Address'
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {

  public step1: boolean;
  public step2: boolean;
  public step3: boolean;
  public submitted: boolean;
  public done: boolean;
  public disableFinish;
  public errorMessage: string;
  public userDetails: FormGroup;
  public tenantDetail: FormGroup;
  public provinces: Array<Province> = new Array<Province>();
  public initiateProgressBar: boolean;
  private ipAddress: string;

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private userService: UserService,
    private establishmentService: EstablishmentService,
    private resourceService: ResourceService,
    private router: Router,
    private locationService: LocationService) {

      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
      this.submitted = false;
      this.done = false;
      this.disableFinish = false;
      this.errorMessage = '';
      this.initiateProgressBar = false;

      this.initUser();
      this.initTenantDetail();
  }

  ngOnInit() {
    this.auth.SignOutSilent();
    this.provinces = this.locationService.getAllProvinces();
    this.userService.getUserIp().subscribe((data: any) => {
      this.ipAddress = (data) ? data.ip : undefined;
    })
  }

  private initUser() {
    this.userDetails = this.fb.group({
      displayName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      reTypePassword: ['', [Validators.required]],
      acceptTermAndConditions: ['', [Validators.required]]
    });
  }

  private initTenantDetail() {
    this.tenantDetail = this.fb.group({
      tenantName: ['', Validators.required],
      tenantEmail: ['', [Validators.required, Validators.email]],
      tenantTelephone: [''],
      tenantWhatsAppNumber: [''],
      tenantStreetName: ['', Validators.required],
      tenantStreetNumber: ['', Validators.required],
      tenantCity: ['', Validators.required],
      tenantProvince: ['', Validators.required],
      tenantComplexName: [''],
      tenantComplexUnitNumber: [''],
      tenantDeliveryInstructions: ['']
    });
  }

  get formUserControls() { return this.userDetails.controls; }
  get formTenantControls() { return this.tenantDetail.controls; }

  public userDetail() {
    this.submitted = true;
    this.errorMessage = '';

    if (!this.userDetails.value.acceptTermAndConditions) {
      this.errorMessage = 'Please accept the Terms and Conditions';
    }

    if (this.userDetails && this.userDetails.valid && this.userDetails.value.acceptTermAndConditions) {

      if (this.userDetails.value.password !== this.userDetails.value.reTypePassword) {
        this.errorMessage = 'Your password does not match. Please retype password';
      } else {
        this.gotoStep2();
      }
    }
  }

  public businessDetails() {

    this.submitted = true;
    this.errorMessage = '';
    if (this.userDetails.value.acceptTermAndConditions &&
      this.formTenantControls.tenantName.valid &&
      this.formTenantControls.tenantEmail.valid) {
      this.gotoStep3();
    }
  }

  public async submitDetails() {

    this.submitted = true;
    this.errorMessage = '';
    if (this.userDetails.value.acceptTermAndConditions && this.tenantDetail && this.tenantDetail.valid) {
      this.disableFinish = true;
      this.initiateProgressBar = true;
      const response = await this.auth.SignUp(this.userDetails.value.email,
        this.userDetails.value.password, this.userDetails.value.displayName);

      const uid = response.userId;

      if (uid && !response.message) {
        const est: Establishment = Establishment
          .fromDataStoreRecord(null,
            {
              tradingAsName: this.tenantDetail.value.tenantName,
              telephoneNumber: this.tenantDetail.value.tenantTelephone,
              emailAddress: this.tenantDetail.value.tenantEmail,
              whatsAppNumber: this.tenantDetail.value.tenantWhatsAppNumber,
              ownerId: uid,
              physicalAddress: {
                streetName: this.tenantDetail.value.tenantStreetName,
                streetNumber: this.tenantDetail.value.tenantStreetNumber,
                city: this.tenantDetail.value.tenantCity,
                province: this.tenantDetail.value.tenantProvince,
                complexName: this.tenantDetail.value.tenantComplexName,
                complexUnitNumber: this.tenantDetail.value.tenantComplexUnitNumber,
                deliveryInstructions: this.tenantDetail.value.tenantDeliveryInstructions
              }
            });

        const esId = await this.establishmentService.addTenantDetails(est);

        const resource: Resource = Resource.fromDataStoreRecord(null,
          {
            name: this.userDetails.value.displayName,
            telephoneNumber: this.tenantDetail.value.tenantTelephone,
            emailAddress: this.userDetails.value.email,
            healthScreenings: [],
            establishmentId: esId
          });

        this.userService.setUserEstablishment(uid, esId);
        this.resourceService.insertResource(resource);
        this.userService.setUserMedium(uid, this.ipAddress);
        this.done = true;
      } else if (response.message) {
        this.errorMessage = response.message;
        this.disableFinish = false;
        this.initiateProgressBar = false;
      }
    }
  }

  public gotoStep1() {
    this.step1 = true;
    this.step2 = false
    this.step3 = false
    this.submitted = false;
  }

  private gotoStep2() {
    this.step1 = false;
    this.step2 = true
    this.step3 = false
    this.submitted = false;
  }

  private gotoStep3() {
    this.step1 = false;
    this.step2 = false
    this.step3 = true
    this.submitted = false;
  }

  public goToLogin() {
    this.router.navigate(['login']);
  }

  getActiveTabName() {
    if (this.step1) {
      this.setActiveTabOverlay(StepDescription.step1);
      return StepDescription.step1;
    }
    if (this.step2) {
      this.setActiveTabOverlay(StepDescription.step2);
      return StepDescription.step2;
    }
    if (this.step3) {
      this.setActiveTabOverlay(StepDescription.step3);
      return StepDescription.step3;
    }
  }

  setActiveTabOverlay(step: StepDescription) {

    // Defaults
    const offset = 8;
    let step_width = $('#nav-step-1').width() + offset;
    let move_distance = -(offset);

    switch (step) {
      case StepDescription.step1:
        step_width = $('#nav-step-1').width() + offset;
        move_distance = -(offset);

        break;
      case StepDescription.step2:
        step_width = $('#nav-step-2').width() + offset;
        move_distance = step_width;

        break;
      case StepDescription.step3:
        step_width = $('#nav-step-3').width() + offset;
        move_distance = (step_width * 2) + (offset * 2);

        break;
    }

    $('.moving-tab').css({
    'width': step_width + 'px',
    'transform': 'translate3d(' + move_distance + 'px, 0px, 0)',
    'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'});

    $('.moving-tab').css({
      'height': $('#nav-step-3').height() + offset + 'px'
    });


  }
}
