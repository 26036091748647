import {NotificationService} from './../../services/notification.service';
import {AuthenticationService} from './../../services/authentication.service';
import {ResourceService} from './../../services/resource.service';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {EstablishmentService} from 'app/services/establishment.service';
import {Router} from '@angular/router';
import {Resource} from 'app/model/resource';
import {Establishment} from 'app/model/establishment';
import {EstablishmentStatusService} from 'app/services/establishment-status-service';
import {SupplierService} from 'app/services/supplier.service';
import {Subscription} from 'rxjs';

declare const $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    auth: boolean;
}

export const ROUTES: RouteInfo[] = [
    {path: '/client-list', title: 'Clients', icon: 'people', class: '', auth: true},
    {path: '/establishment', title: 'Salon', icon: 'business', class: '', auth: true},
    {path: '/shop', title: 'Shop', icon: 'shopping_cart', class: '', auth: true},
    {path: '/order-history', title: 'Orders', icon: 'all_inbox', class: '', auth: true}
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
    menuItems: any[];
    activeEstablishment: Establishment;
    resources: Resource[]
    canLoadAppointmentSubscription: Subscription;
    canLoadAppointments: boolean;

    constructor(private tenantService: EstablishmentService,
                private resourceService: ResourceService,
                private router: Router,
                private authService: AuthenticationService,
                private notificationService: NotificationService,
                private establishmentStatusService: EstablishmentStatusService,
                private supplierService: SupplierService) {

        this.canLoadAppointmentSubscription = this.establishmentStatusService.waitOnLoadAppointmentCheck().subscribe(result => {
            this.canLoadAppointments = result;
            this.activeEstablishment = this.tenantService.getActiveEstablishment();
            if (result) {
                this.loadResources();
            }
        })
    }

    loadResources() {
        this.resourceService.getResources(this.activeEstablishment.id).then(resources => {
            this.resources = resources;
        }).catch(err => {
            console.log(err);
            this.notificationService.showError('Unable to load resource records. Please try again');
        });
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.canLoadAppointmentSubscription.unsubscribe();
    }

    isMobileMenu() {
        return $(window).width() <= 991;
    };

    isLinkedToEstablishment() {
        return this.authService.isLoggedIn && this.establishmentStatusService.isLinkedToEstablishment();
    }

    isLinkedToSupplier(): boolean {
        return this.authService.isLoggedIn && this.supplierService.hasActiveSupplier;
    }

    getTitle() {
        if (this.isLinkedToEstablishment() && this.tenantService.hasActiveEstablishment) {
            return this.tenantService.activeEstablishment.tradingAsName;
        }
        if (this.isLinkedToSupplier() && this.supplierService.hasActiveSupplier) {
            return this.supplierService.activeSupplier.name;
        }
        return 'Cut N Cape';
    }

    onResourceClicked(resource: Resource) {
        this.router.navigate(['appointment-view-select', resource.id]).then((e) => {
            if (e) {
                console.log("Navigation is successful!");
            } else {
                console.log("Navigation has failed!");
            }
        });
    }

    LogOut() {
        this.authService.SignOut();
    }

    goToManageOrders() {
        if (!this.isLinkedToSupplier()) {
            this.notificationService.showError("You are not currently linked to a supplier. Please contact us to associate you to a supplier");
            return;
        }
        this.router.navigate(['supplier-orders']).then((e) => {
            if (e) {
                console.log("Navigation is successful!");
            } else {
                console.log("Navigation has failed!");
            }
        });
    }

    goToFeedback() {
        this.router.navigate(['feedback']).then((e) => {
            if (e) {
                console.log("Navigation is successful!");
            } else {
                console.log("Navigation has failed!");
            }
        });
    }

    canAccessStore(name: string): boolean {
        return (this.activeEstablishment) ? (name === "Shop" || name === "Orders") ? this.activeEstablishment.canAccessStore : true : false;
    }
}
