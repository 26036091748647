import {EstablishmentService} from 'app/services/establishment.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/delay';
import {Client} from 'app/model/client';

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    activeClientList: Client[]
    loadedClients = false;

    constructor(private fireStore: AngularFirestore,
                private establishmentService: EstablishmentService) {
        this.activeClientList = new Array<Client>();
    }

    getClientsForEstablishment(establishmentId: string) {
        if (this.loadedClients) {
            return new Promise((resolve, reject) => {
                resolve(this.activeClientList.sort((a, b) => (a.name > b.name) ? 1 : -1))
            });
        }

        return new Promise((resolve, reject) => {
            return this.fireStore.collection("clients", r => r.where("establishmentId", "==", establishmentId)).get()
                .subscribe(data => {
                    if (data === null || data === undefined || data.docs.length === 0) {
                        resolve(this.activeClientList);
                    } else {
                        this.activeClientList.length = 0;
                        const clientsData = data.docs;
                        clientsData.forEach(element => {
                            const clientData = element.data();
                            const id = element.id;
                            const client = Client.fromDataStoreRecord(id, clientData);
                            this.activeClientList.push(client)
                        });
                        this.loadedClients = true;
                        resolve(this.activeClientList.sort((a, b) => (a.name > b.name) ? 1 : -1));
                    }
                });
        });
    }

    clearActiveClient(): void {
        this.activeClientList = [];
        this.loadedClients = false;
    }

    searchByFieldName(searchText: string, fieldName: string): Promise<Client[]> {
        return this.searchDBByFieldName(fieldName, searchText.toLowerCase());
    }

    duplicateClient(fieldName: string, searchText: string, clientId = "", checkByEstablishment = true): Promise<boolean> {
        return new Promise((resolve) => {
            this.fireStore.collection("clients", r =>
                (checkByEstablishment) ?
                    r.where(fieldName, '==', searchText.trim())
                    .where("establishmentId", "==", this.establishmentService.getActiveEstablishment().id) :
                    r.where(fieldName, '==', searchText.trim())).get().subscribe(data => {
                if (data === null || data === undefined || data.docs.length === 0) {
                    resolve(false);
                } if (clientId && clientId !== "") {
                    const clients = data.docs;
                    let isDuplicate = (clients !== null);

                    if (clients && clients.length === 1) {
                        const id = clients[0].id;

                        if (id && id === clientId) {
                            isDuplicate = false
                        }
                    }

                    resolve(isDuplicate)
                } else {
                    resolve(true);
                }
            });
        })
    }

    private searchDBByFieldName(fieldName: string, searchText: string): Promise<Client[]> {
        return new Promise((resolve, reject) => {
            this.fireStore.collection("clients", r =>
                r.where(fieldName, '>=', searchText.trim())
                    .where(fieldName, '<=', searchText.trim() + '\uf8ff')).get().subscribe(data => {
                if (data === null || data === undefined || data.docs.length === 0) {
                    resolve([]);
                } else {
                    const clientsData = data.docs;
                    const results = [];
                    clientsData.forEach(element => {
                        const clientData = element.data();
                        const establishmentId = clientData.establishmentId;
                        if (establishmentId && establishmentId === this.establishmentService.getActiveEstablishment().id) {
                            const id = element.id;
                            const client = Object.assign(new Client(id, clientData.name), clientData);
                            client.id = id;
                            results.push(client)
                        }
                    });
                    if (results.length === 0) {
                        resolve([]);
                    } else {
                        resolve(results.sort((a, b) => (a.name > b.name) ? 1 : -1));
                    }
                }
            });
        })
    };

    getClientById(id: string): Promise<Client> {
        const clients = this.activeClientList;
        const obj = clients.find(o => o.id === id);
        if (obj) {
            return new Promise((resolve, reject) => {
                resolve(obj)
            });
        }

        return this.remoteGetClientById(id);
    }

    private remoteGetClientById(id: string): Promise<Client> {
        return new Promise((resolve, reject) => {
            this.fireStore.collection('clients').doc(id).get()
                .subscribe(data => {
                    if (data === null || data === undefined || !data.exists) {
                        resolve(null);
                    } else {
                        const clientData = data.data();
                        const id = data.id;
                        const client = Client.fromDataStoreRecord(id, clientData);
                        resolve(client);
                    }
                });
        });
    }

    upSertClient(client: Client): Promise<Client> {
        if (!client.isTransient()) {
            return this.updateClient(client);
        } else {
            return this.insertClient(client);
        }
    }

    deleteClient(id: string) {
        return this.fireStore.collection('clients').doc(id).set({
                deactivateDate: new Date()
            }
            , {merge: true});
    }

    updateClient(updatedClient: Client): Promise<Client> {
        return new Promise((resolve, reject) => {
            return this.fireStore.collection('clients').doc(updatedClient.id).set(
                Object.assign({}, updatedClient.toDataStoreRecord(this.establishmentService.activeEstablishment.id))
                , {merge: true}).then(function (doc) {
                this.deleteFromLocalList(updatedClient.id);
                this.activeClientList.push(updatedClient);
                resolve(updatedClient);
            }.bind(this)).catch(ex => {
                console.log(ex);
            });
        });
    }

    private deleteFromLocalList(id: string) {
        for (let i = this.activeClientList.length - 1; i >= 0; i--) {
            if (this.activeClientList[i].id === id) {
                this.activeClientList.splice(i, 1);
            }
        }
    }

    insertClient(newClient: Client): Promise<Client> {
        return new Promise((resolve, reject) => {
            return this.fireStore.collection("clients").add(Object.assign({}, newClient.toDataStoreRecord(this.establishmentService.activeEstablishment.id))).then(function (doc) {
                newClient.id = doc.id;
                this.activeClientList.push(newClient);
                resolve(newClient);
            }.bind(this)).catch(ex => {
                console.log(ex);
            });
        });
    }
}
