import { NotificationService } from '../services/notification.service';
import { SupplierService } from 'app/services/supplier.service';
import { AuthenticationService } from '../services/authentication.service';
import { Component, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EstablishmentService } from 'app/services/establishment.service';
import { UserService } from 'app/services/user-service';
import { ResourceService } from "../services/resource.service";
import { ClientService } from "../services/client.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  tenantLoginFormGroup: FormGroup;
  submitted = false;
  errorMessage = "";
  loggedIn = false;
  startProgressBar = false;

  constructor(public fb: FormBuilder,
    public authenticationService: AuthenticationService,
    private router: Router,
    public ngZone: NgZone,
    public tenantService: EstablishmentService,
    public supplierService: SupplierService,
    private userService: UserService,
    private resourceService: ResourceService,
    private clientService: ClientService,
    private notificationService: NotificationService) {
    this.createForm();
  }

  createForm() {
    this.tenantLoginFormGroup = this.fb.group({
      tenantEmail: ['', [Validators.email, Validators.required]],
      tenantPassword: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get formControls() { return this.tenantLoginFormGroup.controls; }

  signIn() {
    // stop here if form is invalid
    if (this.tenantLoginFormGroup.invalid) {
      this.errorMessage = "Please enter valid credentials"
      return;
    }
    this.startProgressBar = true;
    this.submitted = true;
    this.errorMessage = "";
    this.tenantLoginFormGroup.disable()

    this.clientService.clearActiveClient();
    this.supplierService.clearSupplier();
    this.resourceService.clearActiveResourceList();
    this.tenantService.clearActiveEstablishment();
    this.tenantService.clearActiveServiceOptions();

    this.authenticationService.SignIn(this.formControls.tenantEmail.value, this.formControls.tenantPassword.value)
      .then(function (result) {
        this.startProgressBar = true;
        if (result.emailVerified !== undefined && !result.emailVerified) {
          this.errorMessage = "Please Verify Your Email Address";
          this.tenantLoginFormGroup.enable()
          this.submitted = false;
        }

        if (!result) {
          this.errorMessage = "Invalid username and/or password"
          this.tenantLoginFormGroup.enable()
          this.submitted = false;
          this.startProgressBar = false;
        } else if (result.emailVerified === undefined) {
          const userDetail = this.authenticationService.userData;
          this.loadUserProfile(userDetail.uid);
          this.startProgressBar = false;
        }
      }.bind(this));
  }

  loadUserProfile(userId: string) {
    this.userService.getUser(userId).then(result => {
      if (result === null) {
        this.errorMessage = "An unexpected error occurred when looking up your user profile. Please try again.";
        this.tenantLoginFormGroup.enable()
        this.submitted = false;
        return;
      }

      if (result.isLinkedToEstablishment) {
        this.tenantService.loadActiveEstablishment(result.establishmentId);
      }

      if (result.isLinkedToSupplier) {
        this.loadSupplierForSignedInUser(result.supplierId);
      }

      if (!result.isLinkedToSupplier && !result.isLinkedToEstablishment) {
        this.errorMessage = "Invalid configuration. Please contact support"
        this.tenantLoginFormGroup.enable();
        this.authenticationService.SignOutSilent();
        return;
      }
      this.loggedIn = true;
      this.router.navigate(['dashboard']);
    }).catch(err => {
      this.errorMessage = "An unexpected error occurred when looking up your user profile. Please try again.";
      this.tenantLoginFormGroup.enable()
      this.submitted = false;
    }).finally(() => {
      this.tenantLoginFormGroup.enable()
      this.submitted = false;
      this.startProgressBar = false;
    }
    )
  }

  loadEstablishmentForSignedInUser(establishmentId: string) {
    this.tenantService.loadActiveEstablishment(establishmentId)
      .then(function (result) {
        if (!result) {
          this.notificationService.showError("Unable to load your establishment details. Please try again");
        }
      }.bind(this));
  }

  loadSupplierForSignedInUser(supplierId: string) {
    this.supplierService.loadActiveSupplier(supplierId)
      .then(function (result) {
        if (!result) {
          this.notificationService.showError("Unable to load your supplier details. Please try again");
        }
      }.bind(this));
  }

  signOut() {
    this.authenticationService.SignOut();
  }

  showLogin() {
    return !this.authenticationService.isLoggedIn || !this.tenantService.hasActiveEstablishment;
  }

  signUp() {
    this.router.navigate(['registration']);
  }
}
