import { Address } from './address';
import { BaseEntity } from './base-entity';

export class Establishment extends BaseEntity {
    tradingAsName: string;
    telephoneNumber: string;
    emailAddress: string;
    whatsAppNumber: string;
    physicalAddress: Address;
    canAccessStore = false;

    public static fromDataStoreRecord(id: string, data: any): Establishment {
        const establishment = Object.assign(new Establishment(id, data.tradingAsName), data);
        establishment.id = id;
        if (data.physicalAddress) {
            establishment.physicalAddress = Object.assign(new Address(), data.physicalAddress);
        }
        return establishment;
    }

    constructor(id: string, name: string) {
        super(id);
        this.tradingAsName = name;
        this.physicalAddress = new Address();
    }

    toDataStoreRecord() {
        const dataStoreRecord = Object.assign({}, this);
        dataStoreRecord.physicalAddress = Object.assign({}, this.physicalAddress);
        return dataStoreRecord;
    }

}
