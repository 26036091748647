import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/components.module';
import {AppComponent} from './app.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {LoginComponent} from './login/login.component';
import {RegistrationComponent} from './registration/registration.component';

/* Firebase services */
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireFunctionsModule} from '@angular/fire/functions';

/* Common */
import {environment} from './../environments/environment';

import {
    MatFormFieldModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatTooltipModule,
    MatIconModule,
    MatNativeDateModule,
    MatAutocompleteModule,
} from '@angular/material';
import {DirectivesModule} from './directives/directives.module';


@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressBarModule,
        MatButtonModule,
        MatInputModule,
        MatRippleModule,
        MatTooltipModule,
        MatIconModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        AppRoutingModule,
        DirectivesModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        LoginComponent,
        RegistrationComponent,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
