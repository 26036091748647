export class Address {
    streetName: string;
    streetNumber: string;
    city: string;
    province: number;
    complexName: string;
    complexUnitNumber: string;
    deliveryInstructions: string;

    constructor() {
        this.streetName = '';
        this.streetNumber = '';
        this.city = '';
        this.province = -1;
        this.complexName = '';
        this.complexUnitNumber = '';
        this.deliveryInstructions = '';
    }
}
