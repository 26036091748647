import UserMedium from "./user-medium";

export class User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  lastLoginDate: Date;
  supplierId: string;
  establishmentId: string;
  userMedium: UserMedium;

  get isLinkedToSupplier(): boolean {
    return this.supplierId !== null && this.supplierId !== undefined;
  }

  get isLinkedToEstablishment(): boolean {
    return this.establishmentId !== null && this.establishmentId !== undefined;
  }

}
