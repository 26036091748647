import { AutofocusDirective } from './auto-focus.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisableControlDirective } from './disable-control-directive';

@NgModule({
  declarations: [DisableControlDirective, AutofocusDirective],
  imports: [CommonModule],
  exports: [DisableControlDirective, AutofocusDirective]
})
export class DirectivesModule { }
