import { BaseEntity } from './base-entity';
import {Representative} from './representative';
import { BankDetail } from './bank-detail';

export class Supplier extends BaseEntity {
    id: string;
    name: string;
    logoUrl: string;
    users: Array<string>;
    representative: Representative;
    bankDetail: BankDetail;
    minimumOrderShippingFee: number;
    shippingFee: number;

    public static fromDataStoreRecord(id: string, supplierData: any): Supplier {
        const supplier = Object.assign(new Supplier(id, supplierData.name), supplierData);
        supplier.id = id;
        supplier.representative = (supplierData.representative) ?
            Representative.fromDataStoreRecord(supplierData.representative.id, supplierData.representative) : null;
        supplier.bankDetail = (supplierData.bankDetail) ?
            BankDetail.fromDataStoreRecord(supplierData.bankDetail.id, supplierData.bankDetail) : null;
        return supplier;
    }

    constructor(id: string, name: string) {
        super(id);
        this.id = id;
        this.name = name
    }

    public toDataStoreRecord() {
        const supplier = Object.assign({}, this);
        if (supplier.representative) {
            supplier.representative =  supplier.representative.toDataStoreRecord();
        }

        if (supplier.bankDetail) {
            supplier.bankDetail =  supplier.bankDetail.toDataStoreRecord();
        }
        return supplier;
    }
}
