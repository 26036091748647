export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyD5nnt4eUdAzEjNsUcQStQ840e8-wQYGr0",
    authDomain: "marshall-zar.firebaseapp.com",
    databaseURL: "https://marshall-zar.firebaseio.com",
    projectId: "marshall-zar",
    storageBucket: "marshall-zar.appspot.com",
    messagingSenderId: "1077515032265",
    appId: "1:1077515032265:web:9f97c888f3fd53420e5f18",
    measurementId: "G-0GL1ZW3GML"
  }  
};
