import { Province } from './../model/province';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    provinces: Array<Province> = new Array<Province>();
    constructor() {
        this.getAllProvinces();
    }

    getAllProvinces(): Array<Province> {
        var easternCape = new Province(1, "Eastern Cape");
        this.provinces.push(easternCape);

        var freeState = new Province(2, "Free State");
        this.provinces.push(freeState);

        var gauteng = new Province(3, "Gauteng");
        this.provinces.push(gauteng);

        var natal = new Province(4, "KwaZulu-Natal");
        this.provinces.push(natal);

        var limpopo = new Province(5, "Limpopo");
        this.provinces.push(limpopo);

        var mpumalanga = new Province(6, "Mpumalanga");
        this.provinces.push(mpumalanga);

        var northWest = new Province(7, "North West");
        this.provinces.push(northWest);

        var nCape = new Province(8, "Northern Cape");
        this.provinces.push(nCape);

        var wCape = new Province(9, "Western Cape");
        this.provinces.push(wCape);

        return this.provinces;
    }

    getProvince(provinceId: number): Province {
        return this.provinces.find(r => r.id === provinceId);
    }
}