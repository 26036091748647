import { AngularFirestore } from '@angular/fire/firestore';
import { SelectionItem } from '../model/data-model';
import { Injectable } from '@angular/core';
import { ServiceOption } from 'app/model/service-option';
import { Establishment } from 'app/model/establishment';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {
  activeServiceOptions: ServiceOption[] = [];
  serviceOptionsLoaded = false;
  activeClientPreferences: SelectionItem[] = [];
  activeMedicalConditions: SelectionItem[] = [];
  activeHealthScreeningQuestions: SelectionItem[] = [];
  activeEstablishment: Establishment = null;
  private establishmentLoadedSubject = new Subject<Establishment>();

  constructor(private fireStore: AngularFirestore) {
    this.loadClientPreferences();
    this.loadMedicalConditions();
  }

  get hasActiveEstablishment(): boolean {
    return this.activeEstablishment !== null;
  }

  clearActiveEstablishment() {
    this.activeEstablishment = null;
  }

  clearActiveServiceOptions() {
    this.serviceOptionsLoaded = false;
    this.activeServiceOptions = [];
  }

  waitOnActiveEstablishmentLoaded(): Observable<Establishment> {
    return this.establishmentLoadedSubject;
  }

  loadActiveEstablishment(establishmentId: string) {
    this.clearActiveEstablishment();
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("establishments").doc(establishmentId).get()
        .subscribe(data => {
          if (data === null || data === undefined || !data.exists) {
            this.clearActiveEstablishment();
            this.establishmentLoadedSubject.next(null);
            resolve(false);
          } else {
            const id = data.id;
            const tenantData = data.data();
            const tenantFromData = Establishment.fromDataStoreRecord(id, tenantData);
            this.activeEstablishment = tenantFromData;
            this.establishmentLoadedSubject.next(this.activeEstablishment);
            resolve(true);
          }
        });
    });
  }

  getServiceOptions(establishmentId: string): Promise<ServiceOption[]> {
    if (this.serviceOptionsLoaded) {
      return new Promise((resolve, reject) => {
        resolve(this.activeServiceOptions.sort((a, b) => (a.name > b.name) ? 1 : -1))
      });
    }

    return new Promise((resolve, reject) => {
      return this.fireStore.collection("serviceOptions", r => r.where("establishmentId", "==", establishmentId)).get()
        .subscribe(data => {
          if (data === null || data === undefined || data.docs.length === 0) {
            resolve(new Array());
          } else {
            this.activeServiceOptions.length = 0;
            const serviceOptionsData = data.docs;

            serviceOptionsData.forEach(element => {
              const serviceData = element.data();
              const id = element.id;
              const client = ServiceOption.fromDataStoreRecord(id, serviceData);
              this.activeServiceOptions.push(client)
            });
            this.serviceOptionsLoaded = true;
            resolve(this.activeServiceOptions.sort((a, b) => (a.name > b.name) ? 1 : -1));
          }
        });
    });
  }

  loadClientPreferences() {
    const prefOne = new SelectionItem(1, "Favorite Drink");
    prefOne.addOption("White Wine");
    prefOne.addOption("Red Wine");
    prefOne.addOption("Coffee - No Milk");
    prefOne.addOption("Coffee - Milk");
    prefOne.addOption("Tea");
    prefOne.addOption("Champagne");
    this.activeClientPreferences.push(prefOne);

    const prefTwo = new SelectionItem(2, "Smoker");
    prefTwo.addOption("Yes");
    prefTwo.addOption("No");
    this.activeClientPreferences.push(prefTwo);

    const prefThree = new SelectionItem(3, "Sensitive Skin");
    prefThree.addOption("Yes");
    prefThree.addOption("No");
    this.activeClientPreferences.push(prefThree);
  }

  loadMedicalConditions() {
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(1, "Dandruff"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(2, "Dry scalp"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(3, "Oily scalp"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(4, "Alopecia areata"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(5, "Over active Thyroid"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(6, "Under active Thyroid"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(7, "Diabetes"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(8, "Hormone replacement therapy"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(9, "Cancer"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(10, "Lupus"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(11, "Specific diet (nutrient deficiencies"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(12, "Stroke"));
    this.activeMedicalConditions.push(this.createNewYesNoSelectionItem(13, "High blood pressure"));
  }

  createNewYesNoSelectionItem(id: number, description: string) {
    const prefItem = new SelectionItem(id, description);
    prefItem.addOption("Yes");
    prefItem.addOption("No");
    return prefItem;
  }

  getActiveEstablishment() {
    return this.activeEstablishment;
  }

  getClientPreferences() {
    return this.activeClientPreferences.sort((a, b) => (a.description > b.description) ? 1 : -1);
  }

  getMedicalConditions() {
    return this.activeMedicalConditions.sort((a, b) => (a.description > b.description) ? 1 : -1);
  }
  getHealthScreeningQuestions() {
    return this.activeHealthScreeningQuestions;
  }

  addTenantDetails(establishment: Establishment): Promise<string> {
    return new Promise((resolve) => {
      return this.fireStore.collection('establishments').add(
        establishment.toDataStoreRecord())
        .then(function (doc) {
          this.activeEstablishment = Establishment.fromDataStoreRecord(doc.id, doc);
          this.fireStore.collection('establishments').doc(doc.id).update({id: doc.id});
          resolve(doc.id);
        }.bind(this)).catch(ex => {
          console.log(ex);
        }).catch(err => {
          console.log(err);
        });
    });
  }

  updateTenantDetails(establishment: Establishment): Promise<Establishment> {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection('establishments').doc(establishment.id).set(
        establishment.toDataStoreRecord()
        , { merge: true }).then(function (doc) {
          this.activeTenant = establishment;
          this.activeEstablishment = establishment;
          resolve(establishment);
        }.bind(this)).catch(ex => {
          console.log(ex);
        }).catch(err => {
          console.log(err);
        });
    });
  }

  getServiceOptionById(id: string): Promise<ServiceOption> {
    const services = this.activeServiceOptions;
    const obj = services.find(o => o.id === id);
    if (obj) {
      return new Promise((resolve, reject) => { resolve(obj) });
    }
    return this.remoteGetServiceOptionById(id);
  }

  private remoteGetServiceOptionById(id: string): Promise<ServiceOption> {
    return new Promise((resolve, reject) => {
      this.fireStore.collection('serviceOptions').doc(id).get()
        .subscribe(data => {
          if (data === null || data === undefined || !data.exists) {
            resolve(null);
          } else {
            const clientData = data.data();
            const clientId = data.id;
            const client = ServiceOption.fromDataStoreRecord(clientId, clientData);
            resolve(client);
          }
        });
    });
  }

  upSertServiceOption(service: ServiceOption): Promise<ServiceOption> {
    if (!service.isTransient()) {
      return this.updateServiceOption(service);
    } else {
      return this.insertServiceOption(service);
    }
  }

  deleteServiceOptionFromLocalList(id: string) {
    for (let i = this.activeServiceOptions.length - 1; i >= 0; i--) {
      if (this.activeServiceOptions[i].id === id) {
        this.activeServiceOptions.splice(i, 1);
      }
    }
  }

  updateServiceOption(updatedServiceOption: ServiceOption): Promise<ServiceOption> {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection('serviceOptions').doc(updatedServiceOption.id).set(
        updatedServiceOption.toDataStoreRecord(this.activeEstablishment.id)
        , { merge: true }).then(function (doc) {
          this.deleteServiceOptionFromLocalList(updatedServiceOption.id);
          this.activeServiceOptions.push(updatedServiceOption);
          resolve(updatedServiceOption);
        }.bind(this)).catch(ex => {
          console.log(ex);
        }).catch(err => {
          console.log(err);
        });
    });
  }

  insertServiceOption(newServiceOption: ServiceOption): Promise<ServiceOption> {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("serviceOptions").add(Object.assign({},
        newServiceOption.toDataStoreRecord(this.activeEstablishment.id))).then(function (doc) {
          newServiceOption.id = doc.id;
          this.activeServiceOptions.push(newServiceOption);
          resolve(newServiceOption);
        }.bind(this)).catch(ex => {
          console.log(ex);
        });
    });
  }
}
