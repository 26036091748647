import { BaseEntity } from "./base-entity";

export class ServiceOption extends BaseEntity {
  name = '';
  price = 0;
  establishmentId: string;

  public static fromDataStoreRecord(id: string, data: any): ServiceOption {
    const record = Object.assign(new ServiceOption(id, data.name, data.price), data);
    record.id = id;
    return record;
  }

  public static hasDuplicateServiceOptions(fromService: ServiceOption[], serviceToCheck: ServiceOption[]): boolean {
    const duplicateServiceOptions = fromService.filter(s => {
      let isDuplicate = false;

      if (s && s.id && serviceToCheck && serviceToCheck.length > 0) {
        isDuplicate = serviceToCheck.filter(sfi => sfi && sfi.id && sfi.id === s.id).length > 1;
      }

      return isDuplicate;
    });
    return (duplicateServiceOptions && duplicateServiceOptions.length > 0)
  }

  constructor(id: string, name: string, price: number) {
    super(id);
    this.name = name;
    this.price = price;
  }

  toDataStoreRecord(establishmentId: string) {
    this.establishmentId = establishmentId;
    const dataStoreRecord = Object.assign({}, this);
    return dataStoreRecord;
  }
}
