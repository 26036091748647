
<!-- CELESTE -->
<div class="main-content-background">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 mr-auto ml-auto mt-5">
        <!--      Wizard container        -->
        <div class="wizard-container">
          <div class="card card-wizard mt-0" data-color="blue" id="wizardProfile">

              <div class="card-header text-center">
                <h3 class="card-title">
                  Set Up Your Salon
                </h3>
                <h5 class="card-description">User and Business information will be needed</h5>
              </div>
              <div class="wizard-navigation">

                <ul id="wizard-navigation-inner" class="nav nav-pills justify-content-between">
                  <li id="nav-step-1" class="nav-item">
                    <a [ngClass]="{'active': step1}" (click)="gotoStep1()" class="nav-link" href="#userDetail" data-toggle="tab" role="tab">
                      User Details
                    </a>
                  </li>
                  <li id="nav-step-2" class="nav-item">
                    <a [ngClass]="{'active': step2}" (click)="userDetail()" class="nav-link" href="#account" data-toggle="tab" role="tab">
                      Business Details
                    </a>
                  </li>
                  <li id="nav-step-3" class="nav-item">
                    <a [ngClass]="{'active': step3}" (click)="businessDetails()" class="nav-link" href="#address" data-toggle="tab" role="tab">
                      Business Address
                    </a>
                  </li>
                </ul>

                <div id="moving-tab" class="moving-tab">
                  {{getActiveTabName()}}
                </div>
                

              </div>
              <div class="card-body pb-0">
                <div class="tab-content">
                  <div [ngClass]="{'active': step1}" class="tab-pane" id="userDetail">
                    <h5 class="text-center font-weight-300"> Please enter your details</h5>
                    
                    <!-- User Details / Registration -->
                    <form [formGroup]="userDetails" (ngSubmit)="userDetail()" *ngIf="step1">

                      <!-- First Name -->
                      <div class="input-group form-control-md flex-nowrap">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="material-icons">face</i>
                          </span>
                        </div>
                        <mat-form-field class="w-100 has-label">
                          <input type="text"
                          name="firstname"
                          matInput
                          id="firstName"
                          placeholder="First Name"
                          formControlName="displayName"
                          [required]="submitted && !formUserControls.displayName.valid">
                          <div *ngIf="submitted && formUserControls.displayName.errors">
                            <div *ngIf="formUserControls.displayName.errors.required" class="text-danger">
                              First Name is required
                            </div>
                          </div>
                        </mat-form-field>
                      </div>

                      <!-- Email -->
                      <div class="input-group form-control-md flex-nowrap">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="material-icons">email</i>
                          </span>
                        </div>
                        <mat-form-field class="w-100 has-label">
                          <input type="text"
                          name="email"
                          matInput
                          id="email"
                          placeholder="Email"
                          formControlName="email"
                          [required]="submitted && !formUserControls.email.valid">
                          <div *ngIf="submitted && formUserControls.email.errors">
                            <div *ngIf="formUserControls.email.errors.required" class="text-danger">
                              Email address is required
                            </div>
                            <div *ngIf="formUserControls.email.errors.email" class="text-danger">Invalid Email Address</div>
                          </div>
                        </mat-form-field>
                      </div>

                      <!-- Password -->
                      <div class="input-group form-control-md flex-nowrap">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="material-icons">lock_outline</i>
                          </span>
                        </div>
                        <mat-form-field class="w-100 has-label">
                          <input type="password"
                          name="password"
                          matInput
                          id="password"
                          placeholder="Password"
                          formControlName="password"
                          [required]="submitted && !formUserControls.password.valid">
                          <div *ngIf="submitted && formUserControls.password.errors">
                            <div *ngIf="formUserControls.password.errors.required" class="text-danger">
                              Password is required
                            </div>
                            <div *ngIf="formUserControls.password.errors.minlength" class="text-danger">Password cannot be smaller
                              than 6 character</div>
                          </div>
                        </mat-form-field>
                      </div>

                      <!-- Password Confirm -->
                      <div class="input-group form-control-md flex-nowrap">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="material-icons">lock_outline</i>
                          </span>
                        </div>
                        <mat-form-field class="w-100 has-label">
                          <input type="password"
                          name="retypePassword"
                          matInput
                          id="retypePassword"
                          placeholder="Confirm Password"
                          formControlName="reTypePassword"
                          [required]="submitted && !formUserControls.reTypePassword.valid">
                        </mat-form-field>
                      </div>
                      
                      <div class="text-right">
                      <mat-checkbox class="primary"  formControlName="acceptTermAndConditions">
                        I agree to the <a href="http://www.cutncape.com/terms-and-conditions.html" target="_blank">Terms and
                          Conditions</a>
                      </mat-checkbox>
                    </div>

                    </form>

                  </div>
                  <form [formGroup]="tenantDetail" *ngIf="!step1">

                    <!-- Business Details -->
                    <div [ngClass]="{'active': step2}" class="tab-pane" id="account" *ngIf="step2 && !done">
                      <h5 class="text-center font-weight-300"> Please fill in your business name and contact details </h5>

                      <!-- Business Name -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="businessName"
                        matInput
                        id="businessName"
                        placeholder="Business Name"
                        formControlName="tenantName"
                        [required]="submitted && !formTenantControls.tenantName.valid">
                        <div *ngIf="submitted && formTenantControls.tenantName.errors">
                          <div *ngIf="formTenantControls.tenantName.errors.required" class="text-danger">
                            Business Name is required
                          </div>
                        </div>
                      </mat-form-field>

                      <!-- Business Telephone -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="telephoneNumber"
                        matInput
                        id="telephoneNumber"
                        placeholder="Telephone Number"
                        formControlName="tenantTelephone">
                      </mat-form-field>

                      <!-- Business WhatsApp -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="whatsAppNumber"
                        matInput
                        id="whatsAppNumber"
                        placeholder="WhatsApp Number"
                        formControlName="tenantWhatsAppNumber">
                      </mat-form-field>

                      <!-- Email Address -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="businessEmail"
                        matInput
                        id="businessEmail"
                        placeholder="Business Email Address"
                        formControlName="tenantEmail"
                        [required]="submitted && !formTenantControls.tenantEmail.valid">
                        <div *ngIf="submitted && formTenantControls.tenantEmail.errors">
                          <div *ngIf="formTenantControls.tenantEmail.errors.required" class="text-danger">
                            Email address is required
                          </div>
                          <div *ngIf="formTenantControls.tenantEmail.errors.email" class="text-danger">Invalid Email Address</div>
                        </div>
                      </mat-form-field>

                    </div>

                    <!-- Business Physical Address -->
                    <div [ngClass]="{'active': step3}" class="tab-pane" id="address" *ngIf="step3 && !done">
                      <h5 class="text-center font-weight-300"> Please fill in your business physical address </h5>
                      
                      <!-- Province -->
                      <mat-form-field class="w-100 has-label">
                        <mat-select placeholder="Province" 
                        formControlName="tenantProvince" 
                        name="businessProvince" 
                        id="businessProvince" 
                        [required]="submitted && !formTenantControls.tenantProvince.valid">
                          <mat-option *ngFor="let option of provinces" [value]="option.id">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                        <div *ngIf="submitted && formTenantControls.tenantProvince.errors">
                          <div *ngIf="formTenantControls.tenantProvince.errors.required" class="text-danger">
                            Please select a province
                          </div>
                        </div>
                      </mat-form-field>

                      <!-- City -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="businessCity"
                        matInput
                        id="businessCity"
                        placeholder="City"
                        formControlName="tenantCity"
                        [required]="submitted && !formTenantControls.tenantCity.valid">
                        <div *ngIf="submitted && formTenantControls.tenantCity.errors">
                          <div *ngIf="formTenantControls.tenantCity.errors.required" class="text-danger">
                            City is required
                          </div>
                        </div>
                      </mat-form-field>

                      <!-- Street Name -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="businessStreetName"
                        matInput
                        id="businessStreetName"
                        placeholder="Street Name"
                        formControlName="tenantStreetName"
                        [required]="submitted && !formTenantControls.tenantStreetName.valid">
                        <div *ngIf="submitted && formTenantControls.tenantStreetName.errors">
                          <div *ngIf="formTenantControls.tenantStreetName.errors.required" class="text-danger">
                            Street Name is required
                          </div>
                        </div>
                      </mat-form-field>  
                      
                      <!-- Street Number -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="businessStreetNumber"
                        matInput
                        id="businessStreetNumber"
                        placeholder="Street Number"
                        formControlName="tenantStreetNumber"
                        [required]="submitted && !formTenantControls.tenantStreetNumber.valid">
                        <div *ngIf="submitted && formTenantControls.tenantStreetNumber.errors">
                          <div *ngIf="formTenantControls.tenantStreetNumber.errors.required" class="text-danger">
                            Street Number is required
                          </div>
                        </div>
                      </mat-form-field>        
                      
                      <!-- Complex Name -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="businessComplexName"
                        matInput
                        id="businessComplexName"
                        placeholder="Complex Name"
                        formControlName="tenantComplexName">
                      </mat-form-field>   

                      <!-- Complex Unit Number -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="businessComplexNumber"
                        matInput
                        id="businessComplexNumber"
                        placeholder="Complex Unit Number"
                        formControlName="tenantComplexUnitNumber">
                      </mat-form-field>     
                      
                      <!-- Special Delivery Instructions -->
                      <mat-form-field class="w-100 has-label">
                        <input type="text"
                        name="businessDeliveryInstructions"
                        matInput
                        id="businessDeliveryInstructions"
                        placeholder="Special Delivery Instructions"
                        formControlName="tenantDeliveryInstructions">
                      </mat-form-field>                       
                      
                    </div>
                  </form>

                  <div *ngIf="done" class="email-verify">

                    <h5 class="text-center p-3 pt-4 font-weight-300">You're almost done setting up your salon...</h5>
          
                    <p class="text-center">We've sent a verification email to 
                      <a href="#email" data-toggle="tab" role="tab">{{formUserControls.email.value}}</a>. <br/>
                      Please follow the link in the email to activate your account.</p>

                  </div>
          
                  <div class="error-message">
                    <div *ngIf="errorMessage.length > 0" class="text-danger text-center">{{errorMessage}}</div>
                  </div>

                </div>
              </div>
              <div class="card-footer">
                <div class="mr-auto">
                  <div *ngIf="step1" class="back-to-login-text">
                    <a href="#" (click)="goToLogin()">Go back to login</a>
                  </div>
                  <input *ngIf="step2" (click)="gotoStep1()" type="button"
                  class="btn btn-previous btn-fill btn-default btn-wd" name="previous" value="Previous">
                  <input *ngIf="step3 && !done" (click)="userDetail()" type="button"
                  class="btn btn-previous btn-fill btn-default btn-wd" name="previous" value="Previous">
                </div>
                <div class="ml-auto">
                  <input *ngIf="step1" type="button" class="btn btn-next btn-fill btn-primary btn-wd" name="next" value="Next" (click)="userDetail()">
                  <input *ngIf="step2" type="button" class="btn btn-next btn-fill btn-primary btn-wd" name="next" value="Next" (click)="businessDetails()">
                  <input *ngIf="step3 && !done" type="button" class="btn btn-fill btn-primary btn-wd" name="finish" value="Finish" [disabled]="initiateProgressBar" (click)="submitDetails()">
                  <button *ngIf="done" mat-raised-button class="btn btn-primary" (click)="goToLogin()">LOGIN</button>
                </div>
                <div class="clearfix"></div>
              </div>
              <div *ngIf="initiateProgressBar && !done && errorMessage.length == 0" class="progress-bar p-0">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
          </div>

        </div>
        <!-- wizard container -->
      </div>
    </div>
  </div>
</div>
<footer class="footer d-none d-sm-block login-reg-footer">
  <div class="container-fluid">
      <!-- Contact Us link -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <p class="mb-0">Contact us at <a href="mailto:support@cutncape.com" class="contact-us-link">support@cutncape.com</a>&nbsp;|&nbsp;</p> 
          <!-- Terms & Conditions link -->
          <p class="mb-0"><a href="http://cutncape.com/terms-and-conditions.html" target="_blank">Terms & Conditions</a></p>
      </div>
  </div>
</footer>
