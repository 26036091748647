import {BaseEntity} from './base-entity';

export class BankDetail extends BaseEntity {

    private readonly accountName: string;
    private readonly bankName: string;
    private readonly accountNumber: string;
    private readonly branchCode: string;

    public static fromDataStoreRecord(id: string, bankDetail: any): BankDetail {
        return Object.assign(new BankDetail(
            id,
            bankDetail.accountName,
            bankDetail.bankName,
            bankDetail.accountNumber,
            bankDetail.branchCode), bankDetail);
    }

    constructor(id: string,
                accountName: string,
                bankName: string,
                accountNumber: string,
                branchCode: string) {
        super(id);
        this.accountName = name;
        this.bankName = bankName;
        this.accountNumber = accountNumber;
        this.branchCode = branchCode;
    }

    public get AccountName(): string {
        return this.accountName;
    }

    public get BankName(): string {
        return this.bankName;
    }

    public get AccountNumber(): string {
        return this.accountNumber;
    }

    public get BranchCode(): string {
        return this.branchCode;
    }

    public toDataStoreRecord(): any {
        return Object.assign({}, this);
    }
}
