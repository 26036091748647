<div class="main-content-background" *ngIf="showLogin()">
  <div class="container">
    <div class="row">

      <div class="login-container col-lg-6 col-md-6 col-12 offset-md-3 offset-0 mt-5">
        <div class="d-flex flex-row justify-content-center align-items-center mb-5">
          <img class="" style="max-width: 250px; max-height: 250px;" src="../../assets/img/cutncape-logo.png">
        </div>
        <form class="form" [formGroup]="tenantLoginFormGroup" (submit)="signIn()">
          <div class="card card-login card-hidden">
            <div class="card-header card-header-info text-center">
              <h4 class="card-title">Log in</h4>
              <!-- <div class="social-line">
                <a href="#" class="btn btn-just-icon btn-link btn-white">
                  <i class="fa fa-facebook-square"></i>
                </a>
              </div> -->
            </div>
            <div *ngIf="submitted" class="progress-bar">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="card-body ">

              <div class="input-group form-control-md flex-nowrap">
                <div class="input-group-prepend">
                  <span class="input-group-text pl-0">
                    <i class="material-icons">email</i>
                  </span>
                </div>
                <mat-form-field class="w-100 has-label">
                  <input matInput 
                         placeholder="Email Address" 
                         type="email" 
                         formControlName="tenantEmail" 
                         name="tenantEmail" 
                         id="tenantEmail" 
                         [required]="submitted && !formControls.tenantEmail.valid">
                  <div *ngIf="submitted && formControls.tenantEmail.errors" class="invalid-feedback">
                    <div *ngIf="formControls.tenantEmail.errors.required">Email is required</div>
                  </div>
                </mat-form-field>
              </div>
              <div class="input-group form-control-md flex-nowrap">
                <div class="input-group-prepend">
                  <span class="input-group-text pl-0">
                    <i class="material-icons">lock_outline</i>
                  </span>
                </div>
                <mat-form-field class="w-100 has-label">
                  <input matInput 
                         placeholder="Password" 
                         type="password" 
                         formControlName="tenantPassword" 
                         name="tenantPassword" 
                         id="tenantPassword">
                </mat-form-field>
              </div>

              <div *ngIf="errorMessage.length > 0" class="row">
                <div class="col-md-12">
                  <div *ngIf="errorMessage.length > 0" class="text-danger text-center">{{errorMessage}}</div>
                  <div *ngIf="errorMessage.length === 0" class="mb-4"> </div>
                </div>
              </div>
            </div>
            <div class="card-footer justify-content-center border-top-0">
              <button mat-raised-button type="button" class="btn btn-primary btn-block mr-3" (click)="signUp()">Register <i class="material-icons">person_add_alt_1</i></button>
            <div class="clearfix"></div>
            <button mat-raised-button type="submit" class="btn btn-primary btn-block" (click)="signIn()"
            [disabled]="submitted">Login <i class="material-icons">login</i></button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>

</div>
<footer class="footer d-none d-sm-block login-reg-footer">
  <div class="container-fluid">
      <!-- Contact Us link -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <p class="mb-0">Contact us at <a href="mailto:support@cutncape.com" class="contact-us-link">support@cutncape.com</a>&nbsp;|&nbsp;</p> 
          <!-- Terms & Conditions link -->
          <p class="mb-0"><a href="http://cutncape.com/terms-and-conditions.html" target="_blank">Terms & Conditions</a></p>
      </div>
  </div>
</footer>







      
