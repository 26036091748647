import { SelectionItemPreference } from 'app/model/data-model';
import { BaseEntity } from './base-entity';

export class Client extends BaseEntity {
    name: string;
    private name_to_search_by;
    telephoneNumber: string;
    whatsAppNumber: string;
    birthDateDay: number;
    birthDateMonth: number;
    birthDateYear: number;
    selectedPreferences: SelectionItemPreference[] = [];
    selectedMedicalConditions: SelectionItemPreference[] = [];
    establishmentId: string;

    public static fromDataStoreRecord(id: string, clientData: any): Client {
        const client = Object.assign(new Client(id, clientData.name), clientData);
        client.id = id;
        return client;
    }

    constructor(id: string, name: string) {
        super(id)
        this.name = name;
        this.NameToSearchBy = name;

        // Set class variables
        this.telephoneNumber = '';
        this.whatsAppNumber = '';
        this.birthDateDay = 0;
        this.birthDateMonth = 0;
        this.birthDateYear = 0;
        this.establishmentId = '';
    }

    private set NameToSearchBy(name: string) {
        this.name_to_search_by = name.toLowerCase().trim();
    }

    setPreferences(newList: SelectionItemPreference[]) {
        this.selectedPreferences.length = 0;
        newList.forEach(r => {
            this.selectedPreferences.push(r);
        }
        );
    }

    setMedicalConditions(newList: SelectionItemPreference[]) {
        this.selectedMedicalConditions.length = 0;
        newList.forEach(r => {
            this.selectedMedicalConditions.push(r);
        }
        );
    }

    toDataStoreRecord(establishmentId: string) {
        this.establishmentId = establishmentId;
        this.telephoneNumber = this.telephoneNumber ? this.telephoneNumber.trim() : this.telephoneNumber;
        const dataStoreClient = Object.assign({}, this);
        dataStoreClient.selectedPreferences = this.selectedPreferences.map((obj) => { return Object.assign({}, obj) });
        dataStoreClient.selectedMedicalConditions = this.selectedMedicalConditions.map((obj) => { return Object.assign({}, obj) });
        return dataStoreClient;
    }

}
