export class BaseEntity {
    public static ActiveDate: Date = new Date(9998, 12, 31);
    id: string;
    deactivateDate: Date;
    createdDate: Date;

    constructor(id: string) {
        this.id = id;
        this.createdDate = new Date();
        this.deactivateDate = BaseEntity.ActiveDate;
    }

    protected delete() {
        this.deactivateDate = new Date();
    }

    public isTransient(): boolean {
        return this.id === '0' ||
            +this.id === 0 ||
            this.id === null ||
            this.id === undefined;
    }
}
