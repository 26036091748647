import {User} from './../model/user';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from '@angular/fire/firestore';
import {DeviceDetectorService} from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private fireStore: AngularFirestore,
                private deviceDetectorService: DeviceDetectorService,
                private http: HttpClient) {
    }

    getUser(userId: string): Promise<User> {
        return new Promise((resolve, reject) => {
            this.fireStore.collection('users').doc(userId).get()
                .subscribe(data => {
                    if (data === null || data === undefined || !data.exists) {
                        resolve(null);
                    } else {
                        const userData = data.data();
                        const user = Object.assign(new User(), userData);
                        resolve(user);
                    }
                });
        });
    }

    setUserEstablishment(userId: string, establishmentId: string) {
        this.fireStore.collection("users").doc(userId).set({establishmentId: establishmentId}, {merge: true})
    }

    getUserIp() {
        return this.http.get("http://api.ipify.org/?format=json");
    }

    setUserMedium(userId: string, ip: string): void {

        const data = {
            dateOfTermAndConditionsAccepted: new Date(),
            userMedium: {
                browser: this.deviceDetectorService.browser,
                browserVersion: this.deviceDetectorService.browser_version,
                os: this.deviceDetectorService.os,
                osVersion: this.deviceDetectorService.os_version,
                userAgent: this.deviceDetectorService.userAgent,
                isMobile: this.deviceDetectorService.isMobile(),
                isTablet: this.deviceDetectorService.isTablet(),
                isDesktop: this.deviceDetectorService.isDesktop(),
                ipAddress: ip ? ip : "UNKNOWN"
            }
        };
        this.fireStore.collection("users").doc(userId).set(data, {merge: true})
            .then(function () {
                console.log('Successfully set user medium');
            }).catch(function (error) {
            console.log('Error trying to set user medium:', error.message);
        });
    }
}
