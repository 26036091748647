import { AngularFirestore } from '@angular/fire/firestore';
import { ProductVolume, ProductCategory, Product } from '../model/data-model';
import { Injectable } from '@angular/core';
import { Supplier } from 'app/model/supplier';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  activeSuppliers: Supplier[] = [];
  activeProductVolumes: ProductVolume[] = [];
  activeSupplier: Supplier = null;
  private supplierLoadedSubject = new Subject<Supplier>();

  constructor(private fireStore: AngularFirestore) {
    this.activeSuppliers = [];
  }

  loadActiveSupplier(supplierId: string) {
    this.clearSupplier();
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("suppliers").doc(supplierId).get()
        .subscribe(data => {
          if (data === null || data === undefined || !data.exists) {
            this.clearSupplier();
            this.supplierLoadedSubject.next(null);
            resolve(false);
          } else {
            const id = data.id;
            const supplierData = data.data();
            const supplier = Supplier.fromDataStoreRecord(id, supplierData);
            this.activeSupplier = supplier;
            this.supplierLoadedSubject.next(supplier);
            resolve(true);
          }
        });
    });
  }

  waitOnActiveSupplierLoaded(): Observable<Supplier> {
    return this.supplierLoadedSubject;
  }

  get hasActiveSupplier(): boolean {
    return this.activeSupplier !== null;
  }

  clearSupplier() {
    this.activeSupplier = null;
  }

  loadSubCategories(parentCategoryId: string) {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("productCategories", r => r.where("parentCategoryId", "==", parentCategoryId)).get()
        .subscribe(data => {
          if (data === null || data === undefined || data.docs.length === 0) {
            resolve(new Array<ProductCategory>());
          } else {
            const dataDocs = data.docs;
            const cats = new Array();
            dataDocs.forEach(element => {
              const id = element.id;
              const category = element.data();
              const cat = Object.assign(new ProductCategory(id, category.name), category);
              cats.push(cat);
            });
            resolve(cats.sort((a, b) => (a.name > b.name) ? 1 : -1));
          }
        });
    });
  }

  loadCategoryProducts(categoryId: string) {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("products", r => r.where("categoryId", "==", categoryId)).get()
        .subscribe(data => {
          if (data === null || data === undefined || data.docs.length === 0) {
            resolve(new Array<Product>());
          } else {
            const dataDocs = data.docs;
            const products = new Array<Product>();
            dataDocs.forEach(element => {
              const id = element.id;
              const productData = element.data();
              const product = Object.assign(new Product(id, productData.name, productData.description, 0), productData);
              product.volume = new ProductVolume(productData.volume.value, productData.volume.unit);
              product.price = productData.price.replace(",", ".")
              products.push(product);
            });
            resolve(products.sort((a, b) => (a.name > b.name) ? 1 : -1));
          }
        });
    });
  }

  getSuppliers() {
    this.activeSuppliers.length = 0;
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("suppliers").get()
        .subscribe(data => {
          if (data === null || data === undefined || data.docs.length === 0) {
            resolve(new Array());
          } else {
            const tenantData = data.docs;
            tenantData.forEach(element => {
              const dataSupplier = element.data();
              const id = element.id;
              this.activeSuppliers.push(Supplier.fromDataStoreRecord(id, dataSupplier));
            });
            resolve(this.activeSuppliers.sort((a, b) => (a.name > b.name) ? 1 : -1));
          }
        });
    });
  }

  getSupplierCategories(supplierId: string) {
    console.log("Supplier ID: " + supplierId);
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("productCategories", r => r.where("supplierId", "==", supplierId).where("parentCategoryId", "==", "")).get()
        .subscribe(data => {
          if (data === null || data === undefined || data.docs.length === 0) {
            resolve(new Array<ProductCategory>());
          } else {
            const dataDocs = data.docs;
            const categories = new Array<ProductCategory>();
            dataDocs.forEach(element => {
              const id = element.id;
              const category = element.data();
              const cat = Object.assign(new ProductCategory(id, category.name), category);
              categories.push(cat);
            });
            resolve(categories.sort((a, b) => (a.name > b.name) ? 1 : -1));
          }
        });
    });
  }

  getProductCategory(id: string) {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("productCategories").doc(id).get()
        .subscribe(data => {
          if (data === null || data === undefined) {
            resolve(false);
          } else {
            const id = data.id;
            const category = data.data();
            const cat = Object.assign(new ProductCategory(id, category.name), category);
            resolve(cat);
          }
        });
    });
  }

  getSupplier(id: string): Promise<Supplier> {
    const existingSupplier = this.activeSuppliers.find(r => r.id == id);

    if (existingSupplier) {
      return new Promise((resolve, reject) => { resolve(existingSupplier) });
    }
    return this.getSupplierFromRemote(id);
  }


  getSupplierFromRemote(id: string): Promise<Supplier> {
    return new Promise((resolve, reject) => {
      return this.fireStore.collection("suppliers").doc(id).get()
        .subscribe(data => {
          if (data === null || data === undefined || !data.exists) {
            resolve(null);
          } else {
            const id = data.id;
            const dataSupplier = data.data();
            const supplier = Supplier.fromDataStoreRecord(id, dataSupplier);
            this.activeSuppliers.push(supplier);
            resolve(supplier);
          }
        });
    });
  }

  getProductById(id: string) {
    return this.remoteGetProductById(id);
  }

  private remoteGetProductById(id: string): Promise<Product> {
    return new Promise((resolve, reject) => {
      this.fireStore.collection('products').doc(id).get()
        .subscribe(data => {
          if (data === null || data === undefined || !data.exists) {
            resolve(null);
          } else {
            const productData = data.data();
            const id = data.id;
            const product = Product.fromDataStoreRecord(id, productData);
            resolve(product);
          }
        });
    });
  }

  getSupplierShippingFees(supplierId: string): Promise<any> {
    return new Promise((resolve) => {
      const suppingFee: any = {supplierName: "No Name", minimumOrderShippingFee: 0, shippingFee: 0};
      this.getSupplier(supplierId).then(supplier => {

        suppingFee.supplierName = supplier.name;
        suppingFee.minimumOrderShippingFee = supplier.minimumOrderShippingFee;
        suppingFee.shippingFee = supplier.shippingFee;

        resolve(suppingFee);
      }).catch(err => {
        resolve(suppingFee);
        console.log('There was error in the view cart', err);
      });
    });
  }
}
